import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import { Helpers } from '../core';
import { receiveMergedSearchResults } from '../search-filter/search-filter-actions';
import { StoreAppAPI } from '../core/src/storeapp-api-client';

const storeAppApi = new StoreAppAPI(); 
const SEARCH_KEY = 'productSearch';
const itemIdQueue = {};

const debouncedProductSearch = debounce( (dispatch, currentSearchResults) => {
    // only fetch products which weren't previously fetched
    const itemIds = Object.keys(itemIdQueue).filter(itemId => !currentSearchResults || !currentSearchResults[itemId])

    if(itemIds.length === 0) return;
    const query = {
        itemId: itemIds.join(','),
        limit: itemIds.length,
        select: 'itemId,sku,name,attributes'
    }
    return storeAppApi.product.search(query).then(resp => {
        const searchResults = resp && resp.data && resp.data.result;
        const parsedSearchResults = Array.isArray(searchResults) && itemIds.reduce((allPrds, itemId) => {
            const productResult = searchResults.find(prd => prd && prd.itemId === itemId);
            allPrds[itemId] = productResult || { fetchFailed: true };
            return allPrds;
        }, {})
        
        dispatch(
            receiveMergedSearchResults(  
                SEARCH_KEY,
                parsedSearchResults
            )
        );

        return parsedSearchResults;
    })
}, 500);

const searchProducts = (itemIds, dispatch, searchedProducts) => {
    for(let itemId of itemIds){
        itemIdQueue[itemId] = true;
    }
    return debouncedProductSearch(dispatch, searchedProducts);
}

const selectProducts = Helpers.memoize((itemIds, searchedProducts) => {
    return itemIds && itemIds.reduce((productResults, itemId) => {
        if(searchedProducts && searchedProducts[itemId]){
            productResults[itemId] = searchedProducts[itemId]
        }
        return productResults;
    }, {})
}, (itemIds, searchedProducts) => `${itemIds && itemIds.join(',')}-${!!searchedProducts}`)

export default itemIds => {
    const dispatch = useDispatch();
    const products = useSelector(state => {
        try{
            return state.searchFilter[SEARCH_KEY].searchResults
        }catch(e){}
    });

    const itemIdKey = itemIds && itemIds.join(',');
    const availableIds = itemIds && itemIds.filter(itemId => products && products[itemId]) 

    useEffect(() => {
        if(itemIds){
            searchProducts(itemIds, dispatch, products);
        }
    }, [itemIdKey]);

    return selectProducts(availableIds, products);
}