import React from 'react';

import { useSelector } from 'react-redux';
import { Carousel, ProductTile } from '@hpstellar/core';

import { Helpers } from '../../../core';
import useProductPrice from '../../../hooks/useProductPrice';
import { getProductShowcaseProps } from '../../../product/get-product-props';

import './einstein-product-tiles.less';

const getSlickSettings = numTiles => ({
    dots: true,
    draggable: true,
    arrows: false,
    equalHeight: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: 'einsteinCarousel',
    mockImagesAmount: 1,
    lazyLoad: 'progressive',
    infinite: true,
    showDotsCounter: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                draggable: true,
                slidesToShow: 1.3,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                infinite: false,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                draggable: true,
                slidesToShow: 2.3,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                infinite: false,
            },
        },
    ],
});

const EinsteinProductTiles = ({ products }) => {
    let { prices } = useProductPrice(products);
    const isMobile = useSelector(state => state && state.ui && state.ui.width < 1024);

    let productTiles =
        products &&
        products.length > 0 &&
        products.map((prd, idx) => {
            let { sku } = prd || {};
            let price = prices[sku] || {};
            let showCaseProps = getProductShowcaseProps(prd, price, false);

            let parsedImages = Helpers.parseHpServicesImages(price);

            if (parsedImages && parsedImages.length > 1) {
                showCaseProps.product.showCaseImage = parsedImages[0];
            }

            if (sku && showCaseProps && showCaseProps.product) {
                showCaseProps.product.sku = sku;
            }

            return (
                <ProductTile
                    className="einstein-tile"
                    key={idx}
                    {...showCaseProps}
                    translations={{
                        buttons: {
                            productLinkBtnText: 'See Product',
                        },
                    }}
                    ratingSpaceReserved={true}
                />
            );
        });

    const numTiles = productTiles && productTiles.length;
    const shouldUseCarousel = isMobile || numTiles > 3;
    return (
        <div className={`einstein-tiles-container${numTiles > 3 ? ' einstein-desktop-carousel' : ''}`}>
            {shouldUseCarousel ? <Carousel {...getSlickSettings(numTiles)}>{productTiles}</Carousel> : productTiles}
        </div>
    );
};

export default EinsteinProductTiles;
