import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDerivedProductMetricsV2, mergeToProductMap, debouncedTrackProductImpression, deleteProductMapKey, priceWasFetched, generateUniqueProductMapKeyForProductList, setMainSku } from '../metrics/metrics-helpers';
import { receiveImpressions } from '../metrics/track-params';
import useSiteConfig from './useSiteConfig';
import { usePageViewTriggered } from './usePageView';

const getSkuKeys = (products, field) => Array.isArray(products) && products.reduce((keys, prd)=>(
  keys + (prd && prd[field] && !prd.analyticsHasNotLazyLoaded ? prd[field] : '')
), '');

const getAvailableProductImpressionsV2 = ({ products, prices, selector, list, xsellMethod, xsellProdInfo, trackedKeys }) => {    
    let productImpressions = products.reduce((allPrdImpressions, prd, idx) => {
        let { sku, name, title, catentryId, position, analyticsHasNotLazyLoaded, gtmUniqueId, prdPosition } = prd || {};
        let hasPrice = priceWasFetched(prices, sku, catentryId);
        if(typeof selector === 'function' && !selector(prd)){
            return allPrdImpressions;
        }
        try{
            // prd.gtmUniqueId = generateUniqueProductMapKey(prd, { vanityUrl, componentName: 'vwa-finderResults', listPosition: idx+1+startPos })
            //TODO: Should gtmUniqueId be set here instead?
            if(sku && gtmUniqueId && !window.productMap[gtmUniqueId] && hasPrice && !trackedKeys[gtmUniqueId]){
                let xsellData = xsellMethod ? { xsellMethod, xsellProdInfo: xsellProdInfo || sku || undefined } : {};
                allPrdImpressions = allPrdImpressions || {};
                allPrdImpressions[gtmUniqueId] = getDerivedProductMetricsV2(prd, prices, {position: prdPosition || (idx+1), list, ...xsellData });
            }
        }catch(e){}

        return allPrdImpressions;
    }, null);

    return productImpressions
}

export default ({ 
    products, 
    componentKey, 
    list, 
    customPrices, 
    key, 
    mutator, 
    xsellMethod,
    xsellProdInfo,
    addUniqueGtmId, 
    disableGtmIdAssignment, 
    lazyLoadKey, 
    selector, 
    isMainSku,
    trackerKey,
    nonImpression,
}) => {
    let [trackedKeys, setTrackedKeys] = useState({});
    let dispatch = useDispatch();
    let siteConfig = useSiteConfig();
    let { enableProductImpressions } = siteConfig || {};
    let prices = useSelector(state => state && state.productData && state.productData.productInfo && state.productData.productInfo.productPrices)
    let productPrices = customPrices || prices;
    let slugInfo = useSelector(state => state && state.slugInfo);
    let { vanityUrl } = slugInfo || {};
    let productsWithAnalytics = products;
    if(!disableGtmIdAssignment) {
        productsWithAnalytics = typeof addUniqueGtmId === 'function' ? addUniqueGtmId(products, { key, componentKey }) : generateUniqueProductMapKeyForProductList(products, { key: `${componentKey}${key ? '-'+key : ''}` })
    }
    let mainSkuIsAvailableBeforePrices = isMainSku && productsWithAnalytics && productsWithAnalytics.length > 0;
    let pageViewTracked = usePageViewTriggered();
    let productKeys = getSkuKeys(productsWithAnalytics, 'sku');
    let priceSkuKeys = productPrices && typeof productPrices === 'object' && Object.keys(productPrices).reduce((skus, sku) => skus + sku + (productPrices[sku].priceFetchError ? 'error' : ''), ''); 

    // reset
    useEffect(() => {
        try{
            if(Object.keys(window.productMap).length > 0 && pageViewTracked) {
                Object.keys(trackedKeys).forEach(deleteProductMapKey);
                setTrackedKeys({});
            }
        }catch(e){}
    }, [trackerKey]);

    useEffect(() => {
        try{
            let mainSkuPrd = mainSkuIsAvailableBeforePrices && getDerivedProductMetricsV2(productsWithAnalytics[0], productPrices, { position: 1, list, xsellMethod, xsellProdInfo });
            mainSkuPrd && setMainSku({ [mainSkuPrd.gtmUniqueId]: mainSkuPrd });
        }catch(e){}
    }, [mainSkuIsAvailableBeforePrices])

    useEffect(() => {
        try{
            const productImpressions = enableProductImpressions && getAvailableProductImpressionsV2({
                products: productsWithAnalytics,
                prices: productPrices,
                list,
                componentKey,
                key,
                vanityUrl,
                xsellMethod,
                xsellProdInfo,
                selector,
                trackedKeys
            })
            if(enableProductImpressions && productImpressions) {
                const impressions = mutator ? mutator( productImpressions ) :  productImpressions;
                !isMainSku && mergeToProductMap(productImpressions, { nonImpression });
                isMainSku && setMainSku(productImpressions);
                // dispatch impressions to trigger pageView when all product data are available
                !pageViewTracked && Object.keys(productImpressions).length >= productsWithAnalytics.length && dispatch(receiveImpressions({
                    impressions,
                    vanityUrl,
                    componentKey,
                    forPageView: true
                  }))
                  setTrackedKeys({
                    ...trackedKeys || {},
                    ...productImpressions
                  })
                  pageViewTracked && !isMainSku && debouncedTrackProductImpression();
            }
        }catch(e){}
    }, [pageViewTracked, productKeys, vanityUrl, priceSkuKeys, enableProductImpressions, lazyLoadKey, isMainSku])

    return (!disableGtmIdAssignment && productsWithAnalytics) || products;
}