export const getProductFromNode = (node, productDataMapper) => {
    try{
        let catentryIdNode = node.querySelector(".igo_product_category_id_value");
        let catentryId = catentryIdNode && catentryIdNode.innerText;

        let productUrlNode = node.querySelector("a");
        let productUrl = productUrlNode && productUrlNode.href;

        let violatorMsgNode = node.querySelector(".igo_product_violator_msg_value");
        let violatorMsg = violatorMsgNode && violatorMsgNode.innerText;

        let imgNode = node.querySelector(".igo_product_image");
        let imageUrl = imgNode && imgNode.src;

        let descNode =  node.querySelector(".igo_product_key_points_value");
        let desc = descNode && descNode.innerText;
        
        let titleNode = node.querySelector('.igo_product_product_name_value');
        let title = titleNode && titleNode.innerText;
        
        let specFirstGlance = desc && desc.split('~').filter(text => text && text.length > 0);

        const data = {
            catentryId,
            violatorMsg,
            imageUrl,
            title,
            name: title,
            specFirstGlance,
            images: [{src: imageUrl, alt: title}],
            ctaViewDetailsLink: productUrl
        }

        return typeof productDataMapper === 'function' ? productDataMapper(data) : data
    }catch(e){}
}

export const collectProducts = (setTileData, einsteinTag, productDataMapper) => {
    let tileData = {
        products: [],
        header: null
    }
    try{
        let allPrinterNodes = document.querySelectorAll(`#${einsteinTag} .igo_product`);
        for(let printerNode of allPrinterNodes){
            let product = getProductFromNode(printerNode, productDataMapper);
            product && tileData.products.push(product);
        }

        let headerNode = document.querySelector(`#${einsteinTag} .igo_boxhead h2`);
        tileData.header = headerNode && headerNode.innerText;
        return setTileData(tileData);
    }catch(e){}

    setTileData(tileData);
}


export const insertScript = (src, successCallback, errorCallback) => {
    try{
        let script = document.querySelector(`head script[src="${src}"]`);
        if(script){
            script.remove();
        }

        let newScript = document.createElement('script');
        newScript.src = src;
        newScript.type = "text/javascript";

        newScript.onload = typeof successCallback === 'function' ? successCallback : () => {};
        newScript.onerror = typeof errorCallback === 'function' ? errorCallback : () => {};

        let head = document.querySelector('head');
        head.appendChild(newScript);
    }catch(e){}
}

export const getEinsteinProductsWithAnalyticFields = (einsteinProducts, productDataMap) => (
    einsteinProducts.map(prd => {
        const { catentryId } = prd || {};
        const { attributes, sku } = (productDataMap && productDataMap[catentryId]) || {};
        return {
            ...prd || {},
            sku: (prd && prd.sku) || sku,
            ...attributes || {}
        }
    })
)