import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { receiveImpressions } from '../metrics/track-params';

/**
 * Custom hook containing shared logic between useProductImpression and usePromottionImpression hooks.
 */
export default props => {
    let { componentKey, isEmpty, setTracked, customImpressions, trackCustomMetric, type, disableImpressions } =
        props || {};

    let {
        vanityUrl: customImpressionsVanityUrl,
        productImpressions: customProductImpressions,
        promotionImpressions: customPromotionImpressions,
    } = customImpressions || {};
    let dispatch = useDispatch();
    let pageViewUrl = useSelector(
        state => state && state.metrics && state.metrics.pageView && state.metrics.pageView.vanityUrl
    );
    let slugInfo = useSelector(state => state && state.slugInfo);
    let userReceived = useSelector(
        state => state && state.userData && (state.userData.updatedFromService || state.userData.userFetchFailed)
    );
    let { vanityUrl } = slugInfo || {};
    let pageViewTracked = vanityUrl === pageViewUrl && userReceived;

    // if isEmpty is true, set the impressions to an empty array in redux to indicate that the page view should be triggered without it
    useEffect(() => {
        if (!pageViewTracked && isEmpty && !disableImpressions) {
            dispatch(
                receiveImpressions({
                    impressions: [],
                    vanityUrl,
                    componentKey,
                })
            );
            setTracked(true);
        }
    }, [isEmpty, pageViewTracked, disableImpressions]);

    useEffect(() => {
        if (customImpressionsVanityUrl && customImpressionsVanityUrl === pageViewUrl && !disableImpressions) {
            const hasPromotionImpressions =
                type === 'promotionImpression' && customPromotionImpressions && customPromotionImpressions.length > 0;
            const hasProductImpressions =
                type === 'productImpression' && customProductImpressions && customProductImpressions.length > 0;
            hasProductImpressions &&
                trackCustomMetric('productImpression', {
                    productImpressions: customProductImpressions,
                });
            hasPromotionImpressions &&
                trackCustomMetric('promotionImpression', {
                    promotions: customPromotionImpressions,
                });
        }
    }, [customImpressionsVanityUrl, pageViewUrl, disableImpressions]);
};
