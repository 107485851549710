import React, { useEffect, useState } from 'react';

import { Typography } from '@hpstellar/core';

import { collectProducts, insertScript, getEinsteinProductsWithAnalyticFields } from './util';
import EinsteinProductTiles from './components/einstein-product-tiles';
import useProductSearch from '../hooks/useProductSearch';
import useUserData, { getPStoreID } from '../hooks/useUserData';

import './einstein.less';

const getScript = ({ einsteinScript, pStoreEinsteinScripts, pStoreID, userFetchFailed }) => {
    let pStoreData = pStoreEinsteinScripts && pStoreEinsteinScripts[pStoreID];
    let pStoreScript = pStoreData && pStoreData.einsteinScript;
    return !userFetchFailed && pStoreScript ? pStoreScript : einsteinScript;
};

export default props => {
    const {
        einsteinScript,
        einsteinTag = 'igdrec_1',
        pStoreEinsteinScripts,
        componentKey,
        shouldUseEinsteinDesign,
        children,
        productDataMapper,
        pStoreExclusion,
    } = props;
    const { userData } = useUserData();
    const { userFetchFailed, updatedFromService } = userData;
    const pStoreID = getPStoreID(userData);
    const [mounted, setMounted] = useState(false);
    const [tileData, setTileData] = useState({});
    const [shouldUseFallback, setShouldUseFallback] = useState(false);
    const { products, header, fetchedCompleteData } = tileData || {};
    const itemIds =
        products &&
        products.reduce((allItemIds, prd) => {
            if (prd && prd.catentryId) {
                allItemIds.push(prd.catentryId);
            }
            return allItemIds;
        }, []);
    const searchProducts = useProductSearch(itemIds);
    const fetchedAllProducts = itemIds && itemIds.every(itemId => searchProducts[itemId]);

    const einsteinScriptSrc = getScript({
        einsteinScript,
        pStoreEinsteinScripts,
        pStoreID,
        userFetchFailed,
    });

    // don't use Einstein if it's disabled for the private store.
    const disabledPstore = pStoreExclusion && pStoreID && pStoreExclusion[pStoreID];

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        // if tileData has products array but it's empty, use the fallback component.
        if (tileData && tileData.products && tileData.products.length === 0) {
            setShouldUseFallback(true);
        }
    }, [tileData]);

    useEffect(() => {
        try {
            // wait until userData has been fetched from HPServices before inserting the script
            if (mounted && einsteinScriptSrc && updatedFromService && !disabledPstore) {
                insertScript(
                    einsteinScriptSrc,
                    () => collectProducts(setTileData, einsteinTag, productDataMapper),
                    () => setShouldUseFallback(true)
                );
            }
        } catch (e) {}
    }, [mounted, einsteinScriptSrc, updatedFromService, disabledPstore]);

    useEffect(() => {
        if (searchProducts && products && fetchedAllProducts) {
            setTileData({
                ...(tileData || {}),
                products: getEinsteinProductsWithAnalyticFields(products, searchProducts),
                fetchedCompleteData: true,
            });
        }
    }, [!!searchProducts, fetchedAllProducts]);

    useEffect(() => {
        // einstein script was not included in graphql response. In this case, render the fallback component
        if (disabledPstore || (!einsteinScriptSrc && props[componentKey])) {
            setShouldUseFallback(true);
        }
    }, [componentKey, einsteinScriptSrc, disabledPstore]);

    if (shouldUseEinsteinDesign && disabledPstore) {
        return null;
    }

    let hasProducts = fetchedCompleteData && products && products.length > 0;
    if (!hasProducts && !shouldUseFallback) {
        return <div key="einstein" id={einsteinTag} className="einstein-script-content" />;
    }

    return shouldUseEinsteinDesign && !children ? (
        <div key="einstein-model-tiles" className="einstein-container">
            <Typography className="stellarFix" tag="h2" variant="display">
                {header}
            </Typography>
            <EinsteinProductTiles products={products} />
        </div>
    ) : (
        children && children(shouldUseFallback ? {} : { products: products })
    );
};
