import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePage from './usePage';
import { getPromotionImpression, receiveImpressions } from '../metrics/track-params';
import { useUserReceived } from './useUserData';
import useSharedImpressions from './useSharedImpressions';
import useSiteConfig from '../hooks/useSiteConfig';

/**
 * Triggers promotionImpression events
 */
export default props => {
    let { analyticsData, data, id, componentKey, trackMetrics, isEmpty, customImpressions } = props || {}; 

    let { trackCustomMetric } = usePage();
    let dispatch = useDispatch();
    let pageViewUrl = useSelector(state => state && state.metrics && state.metrics.pageView && state.metrics.pageView.vanityUrl);
    let slugInfo = useSelector(state => state && state.slugInfo);
    let userReceived = useUserReceived();
    let { vanityUrl } = slugInfo || {};
    let [tracked, setTracked] = useState(false);
    let [promotionImpressions, setPromotionImpressions] = useState(customImpressions); 
    let siteConfig = useSiteConfig();
    let { enableProductImpressions, enablePromotionImpressions } = siteConfig || {};

    const hasValidData = data && data.length > 0;

    // set the promotions in promotionMap and promotionImpressions state
    useEffect(() => {
        if(enablePromotionImpressions && !tracked && hasValidData && !customImpressions){ 
          let promos = getPromotionImpression(data, id, { analyticsData });
          promos && promos.length > 0 && setPromotionImpressions(promos);
          dispatch(receiveImpressions({
              impressions: promos,
              vanityUrl,
              componentKey
          }))
        }
    }, [hasValidData, enablePromotionImpressions]);

    // trigger promotionImpression event if not tracked yet AND the last tracked page view is the current page AND there's valud promotionImpressions
    useEffect(() => {
        if(enablePromotionImpressions && trackMetrics && promotionImpressions && !tracked && vanityUrl === pageViewUrl && userReceived && !customImpressions){ 
            trackCustomMetric('promotionImpression', {
                promotions: promotionImpressions
            });
            setTracked(true);
        }
    }, [!!promotionImpressions, vanityUrl, pageViewUrl, userReceived, trackMetrics, !!customImpressions, enablePromotionImpressions]); 

    //shared logic between usePromotionImpressions and useProductImpressions
    useSharedImpressions({ 
        isEmpty,
        setTracked,
        componentKey, 
        trackCustomMetric, 
        customImpressions, 
        type: 'promotionImpression',
        disableImpressions: !enablePromotionImpressions
    });

    return promotionImpressions;
}