import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    addToCart,
    addMultipleToCart,
    addCTOToCart,
    launchCartFlyout,
    updateCart,
    updateCartBySKU,
    fetchCart,
    REFRESH_CART,
} from '../utility/utility-actions';
import { Helpers } from '../core/src/helpers';
import { confirmAddToCart } from '../utility/components/add-to-cart-confirm/add-to-cart-confirm-actions';
import { getCTOLink } from '../core/src/etr-api-client/cto';
import usePageLoader from './usePageLoader';

const EMPTY_OBJ = {};
/**
 *
 * @param {boolean} trackAddToCart option to track add to cart success with addedToCart return prop
 *
 */

/**
 * @typedef {Object} AddToCartFunctions
 * @property {function(): promise} addCTOToCart - Adds a CTO preconfig to cart
 * @property {function(): promise} addToCart - Adds a STO product to cart
 * @property {function(): promise} addMultipleToCart - Add multiple STO products to cart
 * @property {function(): promise} goToCTOPage - Link to CTO page using react router
 * @property {function(): promise} launchCartFlyout - Launch cart flyout with last item added to cart displayed
 * @property {boolean} addedToCart - boolean indicator if item has been added to cart, has 2 sec reset
 */

/**
 * Returns redux connected add to cart functions
 * @name useAddToCart
 * @returns {AddToCartFunctions}
 */
export default function useAddToCart(trackAddToCart, options = EMPTY_OBJ) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loader, setPageLoader] = usePageLoader();
    const { enablePageLoader } = options;
    const ctoConfigurations = useSelector(state => {
        const { withCTOConfigurations } = options;
        if (withCTOConfigurations) {
            return state.productData.ctoConfigurations;
        }
        return null;
    });
    const siteConfig = useSelector(state => state.siteConfig || EMPTY_OBJ);

    const [addedToCart, setAddedToCart] = useState({});

    useEffect(() => {
        let timer;
        if (Object.keys(addedToCart).length > 0) {
            timer = setTimeout(() => {
                setAddedToCart({});
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [addedToCart]);

    return {
        addCTOToCart: (product, quantity, blockFlyout, followRedirect) => {
            return dispatch(
                addCTOToCart(
                    product,
                    quantity,
                    blockFlyout,
                    followRedirect,
                    () => {
                        setPageLoader({ loading: true });
                    },
                    () => {
                        setPageLoader({ loading: false });
                    }
                )
            );
        },
        addToCart: (product, quantity, blockFlyout, addOns, options, baseProductSku, xsellType, metricParams) => {
            trackAddToCart && setAddedToCart({ [product.sku]: true });
            const setAddToCartLoad = enablePageLoader
                ? value => {
                      setPageLoader({ loading: value });
                  }
                : () => {};
            setAddToCartLoad(true);
            return dispatch(
                addToCart(product, quantity, blockFlyout, addOns, options, baseProductSku, xsellType, metricParams)
            )
                .then(resp => {
                    setAddToCartLoad(false);
                    return resp;
                })
                .catch(e => {
                    setAddToCartLoad(false);
                    return e;
                });
        },
        addMultipleToCart: (products, quantity, blockFlyout, options, baseProductSku, xsellType, metricParams) =>
            dispatch(
                addMultipleToCart(products, quantity, blockFlyout, options, baseProductSku, xsellType, metricParams)
            ),
        goToCTOPage: (product, quantity, additionalParams = {}) => {
            const { sku } = product;
            const ctoParams = (ctoConfigurations && ctoConfigurations[sku]) || {};
            const catEntryId = product.itemId || product.catentryId || product.catEntryId;
            const ctoLink = getCTOLink(catEntryId, quantity, { ...additionalParams, ...ctoParams });
            history.push(ctoLink);
        },
        goToDestination: url => {
            if (!url) {
                return;
            }
            if (url.indexOf(siteConfig.basename) > -1) {
                history.push(Helpers.getRelativePath(url));
            } else {
                document.location.href = url;
            }
        },
        getCTOLink: (product, quantity, additionalParams = {}) => {
            const { sku } = product;
            const ctoParams = (ctoConfigurations && ctoConfigurations[sku]) || {};
            const catEntryId = product.itemId || product.catentryId || product.catEntryId;
            return getCTOLink(catEntryId, quantity, { ...additionalParams, ...ctoParams });
        },
        updateCart: (cartItem, quantity) => dispatch(updateCart(cartItem, quantity)),
        updateCartBySKU: (sku, qty) => dispatch(updateCartBySKU(sku, qty)),
        launchCartFlyout: payload => dispatch(launchCartFlyout(payload)),
        fetchCart: () => dispatch(fetchCart(REFRESH_CART)),
        confirmAddToCart: (confirmType, params) => dispatch(confirmAddToCart(confirmType, params)),
        addedToCart,
    };
}
