import React from 'react';
import Einstein from './';
import withPageComponents from '../page/components/with-page-components';

export default (Component, componentKey, productDataMapper) => (
    withPageComponents(
        props => {
            const { einsteinConfig } = props[componentKey] || {};
            const { einsteinTag, pStoreEinsteinScripts, pStoreExclusion, einsteinScript } = einsteinConfig || {};

            return (
                <Einstein
                    einsteinScript={einsteinScript} 
                    einsteinTag={einsteinTag}
                    componentKey={componentKey}
                    productDataMapper={productDataMapper}
                    pStoreEinsteinScripts={pStoreEinsteinScripts}
                    pStoreExclusion={pStoreExclusion}
                >
                    {
                        ({ products }) => (
                            <Component einsteinProducts={products} />
                        )
                    }
                </Einstein>
            )
        },
       { components: [componentKey] }
    )
)